import { ServiceFactory } from '@/services/ServiceFactory'
const _clientSearch = ServiceFactory.get('ClientSearchService')
const _feedbackService = ServiceFactory.get('FeedbackService')

export default {
  data () {
    return {
      dialog: false,
      headers: [
        {
          text: 'Restaurante',
          align: 'start',
          sortable: false,
          value: 'business.name'
        },
        { text: 'Codigo', sortable: false, value: 'code' },
        { text: 'Descuento', sortable: false, value: 'discount' },
        { text: 'Cant. pers.', sortable: false, value: 'numberPeople' },
        { text: 'Fecha', sortable: false, value: 'date' },
        { text: 'Hora', sortable: false, value: 'time' },
        { text: 'Estado', sortable: false, value: 'state' }
      ],
      items: [],
      user: {},
      userId: '',
      postData: {
        comment: ''
      },
      comments: [],
      loading: false
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.postData.comment = ''
      if (this.$route.params.id) {
        this.userId = this.$route.params.id
        try {
          const historyResp = await _clientSearch.feedbackUser(this.userId)
          console.log(historyResp)
          this.user = historyResp.user
          this.items = historyResp.bookings
          const feedbackResp = await _feedbackService.index(this.userId)
          this.comments = feedbackResp.feedback
          console.log(feedbackResp)
        } catch (error) {
          console.log(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'feedback' })
      }
    },
    openComentary () {
      this.dialog = true
    },
    async submitComment () {
      this.loading = true
      try {
        console.log(this.postData.comment)
        const submitResp = await _feedbackService.create(this.userId, this.postData)
        console.log(submitResp)
        this.$notify({
          type: 'success',
          text: 'Datos modificados correctamente'
        })
        this.loading = false
        this.initialize()
        this.dialog = false
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al guardar el comentario, intente nuevamente'
        })
        this.loading = false
        this.dialog = false
      }
    },
    goBack () {
      this.$router.push({ name: 'feedback' })
    }
  }
}
